import { USDC_ADDRESS } from '@/config'
import { BaseService } from '@/services/BaseService'
import { formatSolToLamports } from '@/utils'
import { type PriceResponse } from '@lavarage/entities'
import { PublicKey } from '@solana/web3.js'
import type BigNumber from 'bignumber.js'

export class JupiterSource extends BaseService {
  readonly urls = {
    swapQuote: (from: string, to: string, amount: string, slippage: number, jupiterPlatformFeeBps: number) => `https://quote-api.jup.ag/v6/quote?inputMint=${from}&outputMint=${to}&amount=${amount}&slippageBps=${Math.floor(slippage)}&swapMode=ExactIn&onlyDirectRoutes=false&asLegacyTransaction=false&maxAccounts=26&minimizeSlippage=false&swapType=aggregator&tokenCategoryBasedIntermediateTokens=true&platformFeeBps=${jupiterPlatformFeeBps}`,
    swap: 'https://quote-api.jup.ag/v6/swap',
    swapIx: 'https://quote-api.jup.ag/v6/swap-instructions',
  } as const
  readonly fallbackUrls = {
    swapQuote: (from: string, to: string, amount: string, slippage: number, jupiterPlatformFeeBps: number) => `https://public.jupiterapi.com/quote?inputMint=${from}&outputMint=${to}&amount=${amount}&slippageBps=${Math.floor(slippage)}&swapMode=ExactIn&onlyDirectRoutes=false&asLegacyTransaction=false&maxAccounts=26&minimizeSlippage=false&swapType=aggregator&tokenCategoryBasedIntermediateTokens=true&platformFeeBps=${jupiterPlatformFeeBps}`,
    swap: 'https://public.jupiterapi.com/swap',
    swapIx: 'https://public.jupiterapi.com/swap-instructions',
  } as const

  async getPrice(baseCurrencies: string, vsToken = USDC_ADDRESS): Promise<PriceResponse> {
    const response = await this.http(`https://api.jup.ag/price/v2?ids=${baseCurrencies}&vsToken=${vsToken}`)
    if (response.status < 200 || 300 <= response.status) {
      throw new Error('failed to fetch tokens')
    }
    return response.data
  }

  async getSwapTx(from: string, to: string, amount: number, slippage: number, userPubKey: string, jupiterPlatformFeeBps: number) {
    const quoteResponse = await this.getSwapQuote(from, to, formatSolToLamports(amount), slippage, jupiterPlatformFeeBps)
    try {
      const result = await (
        await this.http.post(this.urls.swap, {
          quoteResponse,
          userPublicKey: new PublicKey(userPubKey),
          wrapAndUnwrapSol: true,
        })
      ).data
      return result
    }
    catch (e) {
      console.log('fallback to jup.ag')
      const result = await (
        await this.http.post(this.fallbackUrls.swap, {
          quoteResponse,
          userPublicKey: new PublicKey(userPubKey),
          wrapAndUnwrapSol: true,
        })
      ).data
      return result
    }
  }

  async getSwapIx(from: string, to: string, amount: BigNumber, slippage: number, userPubKey: string, jupiterPlatformFeeBps: number, positionAccountTokenAccountPubKey?: string, buy?: boolean, swap?: boolean) {
    const quoteResponse = await this.getSwapQuote(from, to, amount, slippage, jupiterPlatformFeeBps)
    const [feeAccount] = PublicKey.findProgramAddressSync(
      [
        Buffer.from('referral_ata'),
        new PublicKey('A55dyZ5rBz4UtB2ursLhGS7CzXMAJM7bKm3StJWAtew1').toBuffer(),
        new PublicKey(buy ? from : to).toBuffer(),
      ],
      new PublicKey('REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3'),
    )
    try {
      const instructions = await (
        await this.http.post(this.urls.swapIx, {
          quoteResponse,
          userPublicKey: userPubKey,
          // only when SOL is a base currency
          wrapAndUnwrapSol: swap ? true : (from === 'So11111111111111111111111111111111111111112' && buy) || (to === 'So11111111111111111111111111111111111111112' && !buy),
          destinationTokenAccount: positionAccountTokenAccountPubKey,
          feeAccount,
        })
      ).data

      return { instructions, quoteResponse }
    }
    catch (e) {
      console.log('fallback to jup.ag')
      const instructions = await (
        await this.http.post(this.fallbackUrls.swapIx, {
          quoteResponse,
          userPublicKey: userPubKey,
          wrapAndUnwrapSol: true,
          destinationTokenAccount: positionAccountTokenAccountPubKey,
          feeAccount,
        })
      ).data

      return { instructions, quoteResponse }
    }
  }
  private async getSwapQuote(from: string, to: string, amount: BigNumber, slippage: number, jupiterPlatformFeeBps: number) {
    const amountInLamports = amount.toString().split('.')[0]
    try {
      const response = await this.http.get(this.urls.swapQuote(from, to, amountInLamports, slippage * 100, jupiterPlatformFeeBps))

      return response.data
    }
    catch (e) {
      console.log('fallback to jup.ag')
      const response = await this.http.get(this.fallbackUrls.swapQuote(from, to, amountInLamports, slippage * 100, jupiterPlatformFeeBps))

      return response.data
    }
  }
}

export const jupiterSource = new JupiterSource()
