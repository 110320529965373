import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconX } from '../../assets/index.js'
import { Conditional } from './Conditional.js'
import { Modal } from './Modal.js'
import { Button } from './buttons/Button.js'

interface MarketingModalProps {
  ImageComponent: React.ReactNode
  title?: string // Optional title
  ctaText?: string // Optional CTA button text
  ctaUrl?: string // Optional CTA button link
  path?: string // Optional path to navigate to
  delay?: number // Delay in milliseconds before showing the modal
  forceReappearKey?: string // Unique key to force reappearance for new campaigns
  children?: React.ReactNode // Optional description (can include HTML or formatted text)
}

export const MarketingModal = ({
  ImageComponent,
  title,
  ctaText,
  ctaUrl,
  path,
  delay = 0,
  forceReappearKey = 'default_campaign',
  children,
}: MarketingModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const closeModal = () => {
    setIsModalVisible(false)
    localStorage.setItem(`marketingModal_${forceReappearKey}`, 'dismissed')
  }

  useEffect(() => {
    const modalState = localStorage.getItem(`marketingModal_${forceReappearKey}`)
    if (modalState !== 'dismissed') {
      const timeout = setTimeout(() => setIsModalVisible(true), delay)
      return () => clearTimeout(timeout)
    }
  }, [delay, forceReappearKey])

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (isModalVisible && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        inputRef?.current?.blur()
        closeModal()
      }
    },
    [isModalVisible],
  )

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside as EventListener)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside as EventListener)
    }
  }, [isModalVisible, handleClickOutside])

  useEffect(() => {
    if (isModalVisible) {
      const targetElement = wrapperRef.current
      if (targetElement) disableBodyScroll(targetElement)
      return () => {
        if (targetElement) enableBodyScroll(targetElement)
      }
    }
  }, [isModalVisible])

  const location = useLocation()
  const navigate = useNavigate()
  const ctaAction = useCallback(() => {
    if (path) {
      navigate({ pathname: path, search: location.search })
    }
    else if (ctaUrl) {
      window.open(ctaUrl, '_blank')
    }
  }, [path, ctaUrl, navigate, location.search])

  return (
    <Conditional if={isModalVisible}>
      <Modal className='overflow-hidden'>
        <div ref={wrapperRef} className='bg-vibrant max-h-screen max-w-[680px] overflow-y-auto rounded-lg'>
          <div className='flex justify-between p-[10px]'>
            <button
              className='transition-opacity hover:opacity-70'
              onClick={closeModal}
            >
              <IconX/>
            </button>
            <span className='py-[2.5px] text-center text-base'>{title}</span>
            <button className='opacity-0'>
              <IconX/>
            </button>
          </div>
          {ImageComponent}
          {children}
          <div className='flex justify-center py-[10px]'>
            <Button color='gradient' size='adaptive' onClick={ctaAction} >
              {ctaText}
            </Button>
          </div>
        </div>
      </Modal>
    </Conditional>
  )
}
