import type { Position } from '@lavarage/entities'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { IconEmptyOpenPosition } from '../../../../assets/index.js'
import { referralService } from '../../../../services/ReferralService.js'
import { type TokenInfo } from '../../../../services/types.js'
import { Button, Conditional, For } from '../../../components/index.js'
import { clsxm } from '../../../helpers/index.js'
import { useClickOutsideEffect } from '../../../hooks/useClickOutsideEffect.js'
import { usePositions } from '../../../hooks/usePositions.js'
import { CloseModal } from './CloseModal.js'
import { PositionCard } from './PositionCard.js'
import { PositionCardsGrid } from './PositionCardsGrid.js'
import { ShareModal } from './ShareModal.js'
import { TakeProfitModal } from './TakeProfitModal.js'

export const OpenPositions = () => {
  const { tokens, collateralsUSD: prices } = useOutletContext<{ tokens: TokenInfo[]; collateralsUSD: Record<string, { price: number }> }>()
  const { openPositions, refresh } = usePositions()
  const { connected } = useWallet()
  const { setVisible } = useWalletModal()
  const [focusedId, setFocusedId] = useState<string | null>(null)
  const [closingPosition, setClosingPosition] = useState<Position | undefined>(undefined)
  const [sharingPosition, setSharingPosition] = useState<Position | undefined>(undefined)
  const closeModalRef = useRef<HTMLDivElement>(null)
  const shareModalRef = useRef<HTMLDivElement>(null)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const hasOpenPositions = openPositions.length > 0
  const [takeProfit, setTakeProfit] = useState<Position | undefined>(undefined)
  const takeProfitModalRef = useRef<HTMLDivElement>(null)

  const cancelClosingPosition = () => {
    setClosingPosition(undefined)
    refresh()
  }

  const cancelSharingPosition = () => {
    setSharingPosition(undefined)
    refresh()
  }

  useEffect(() => {
    refresh()
  }, [refresh])

  useClickOutsideEffect(() => {
    cancelClosingPosition()
  }, { ref: closeModalRef, isActive: !!closingPosition })

  useClickOutsideEffect(() => {
    cancelSharingPosition()
  }, { ref: shareModalRef, isActive: !!sharingPosition })

  const cancelTakeProfit = () => {
    setTakeProfit(undefined)
    refresh()
  }
  useClickOutsideEffect(() => {
    cancelTakeProfit()
  }, { ref: takeProfitModalRef, isActive: !!takeProfit })

  const { publicKey } = useWallet()
  const [refCode, setRefCode] = useState('')
  useEffect(() => {
    const fetchReferralCode = async () => {
      if (publicKey) {
        const refCodeFromService = await referralService.getReferralCode(publicKey.toBase58())
        if (refCodeFromService) setRefCode(refCodeFromService)
      }
    }
    fetchReferralCode()
  }, [publicKey])

  return (
    <>
      <div className={clsxm('flex flex-1 flex-col py-6')}>
        <Conditional if={hasOpenPositions}>
          <PositionCardsGrid>
            <For of={openPositions}>
              {(position, idx) => (
                <PositionCard
                  key={position.publicKey}
                  isExpanded={position.publicKey === focusedId}
                  position={position}
                  prices={prices}
                  timezone={timezone}
                  tokens={tokens}
                  onClose={setClosingPosition}
                  onShare={setSharingPosition}
                  onTakeProfit={setTakeProfit}
                  onToggleDetails={() => setFocusedId(position.publicKey === focusedId ? null : position.publicKey)}
                />
              )}
            </For>
          </PositionCardsGrid>
        </Conditional>
        <Conditional if={!hasOpenPositions}>
          <div className='m-auto flex max-w-[400px] flex-col items-center justify-center gap-5 text-center text-base'>
            <IconEmptyOpenPosition/>
            <Conditional if={connected}>
              <p>You have no open positions (newly opened positions may take a few minutes to appear).</p>
            </Conditional>
            <Conditional if={!connected}>
              <p>Connect your wallet to view your open positions.</p>
              <Button color='gradient' size='m' onClick={() => setVisible(true)}>Connect Wallet</Button>
            </Conditional>
          </div>
        </Conditional>
      </div>
      {closingPosition && (
        <CloseModal
          ref={closeModalRef}
          position={closingPosition}
          prices={prices}
          timezone={timezone}
          tokens={tokens}
          onExit={() => setClosingPosition(undefined)}
        />
      )}
      {sharingPosition && (
        <ShareModal
          ref={shareModalRef}
          position={sharingPosition}
          prices={prices}
          refCode={refCode}
          timezone={timezone}
          onExit={() => setSharingPosition(undefined)}
        />
      )}
      {takeProfit && (
        <TakeProfitModal
          ref={takeProfitModalRef}
          position={takeProfit}
          prices={prices}
          timezone={timezone}
          onExit={() => setTakeProfit(undefined)}
        />
      )}
    </>
  )
}
